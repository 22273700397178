export default [
  {
    url: "/doctor/notice-board",
    name: "Dashboard",
    icon: "MonitorIcon",
    slug: "dashboard",
    i18n: process.env.VUE_APP_REGION === "US" ? "Notice Board" : "Dashboard",
  },
  ...process.env.VUE_APP_REGION !== "US" ? [
    {
      url: "/doctor/clinics",
      name: "Clinic Management",
      icon: "HomeIcon",
      slug: "clinics",
      i18n: "Clinics",
    },
  ] : [],
  {
    url: "/doctor/patients",
    name: "Patient Management",
    icon: "FolderPlusIcon",
    slug: "patients",
    i18n: "Patients",
  },
  // {
  //   url: "/doctor/treatment-history",
  //   name: "Treatment History",
  //   icon: "ActivityIcon",
  //   slug: "treatment-history",
  //   i18n: "Treatments",
  // },
  {
    url: "/doctor/events",
    name: "Events",
    icon: "CalendarIcon",
    slug: "events",
    i18n: "Events",
  },
  {
    url: "/doctor/resources",
    name: "Resources",
    icon: "CodepenIcon",
    slug: "resources",
    i18n: "Resources",
  },
];
